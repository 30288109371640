import React, { ReactNode } from 'react';
import { SideBarMenu } from './SideBarMenu';
import { Header } from './Header';
import { Footer } from './Footer';

type Props = {
    children: ReactNode;
    displayHeader?: boolean;
};

export const MainLayout = ({ children, displayHeader = true }: Props) => {
    const HEIGTH = '100px';
    const BREAK_POINT = 'lg';

    return (
        <div className="d-flex flex-column vh-100">
            {displayHeader && <Header height={HEIGTH} breakPoint={BREAK_POINT} />}
            <main className="container" style={{ marginTop: displayHeader ? HEIGTH : 0 }}>
                <div className="d-flex justify-content-between">
                    <nav className="d-none d-lg-block bg-white sidebar position-fixed">
                        <div className="sidebar-sticky">
                            <SideBarMenu
                                breakPoint={BREAK_POINT}
                                showBackendButton
                            />
                        </div>
                    </nav>

                    <div className="col-lg-2" style={{ width: '15%' }}></div>

                    <main role="main" className="d-none d-lg-block col-lg-10 ml-sm-auto" style={{ width: '85%' }}>
                        <div>{children}</div>
                    </main>
                    <main role="main" className="col-12 d-lg-none ml-sm-auto">
                        <div>{children}</div>
                    </main>
                </div>
            </main>
            <Footer />
        </div>
    );
};
